import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import './assets/base.css'
import * as Sentry from '@sentry/vue'
import { Constants } from './utils/enums'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(ElementPlus)

if (Constants['ENV_NAME'] !== 'dev') {
  Sentry.init({
    app,
    dsn: Constants['SENTRY_DSN'],
    environment: Constants['ENV_NAME'],
    integrations: [
      Sentry.browserTracingIntegration({ router, routeLabel: 'path' }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.mount('#app')
