export const Constants = {
  BASE_URL: import.meta.env.VITE_BASE_URL,
  BASE_URL_API: import.meta.env.VITE_BASE_URL_API,
  BASE_URL_CONSOLE: import.meta.env.VITE_BASE_URL_CONSOLE,
  GP_LOAD_COOKIE_URL: import.meta.env.VITE_GP_LOAD_COOKIE_URL,
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN
} as const
export type Constants = keyof typeof Constants

export const colorPalette: Record<string, Record<string, string>> = {
  service: {
    primary: '#1C386E',
    lighter: '#607399',
    secondary: '#E8E8E8',
    accent: '#97264D',
    accentPale: 'rgba(151,38,77,0.5)'
  },
  bg: {
    base: '#F8F9FA',
    f: '#FFFFFF',
    fPale: 'rgba(255,255,255,0.05)',
    primary: '#1c386E',
    darker: '#0C1931',
    lighter: '#607399',
    lighterPale: 'rgba(96,115,153,0.05)',
    secondary: '#F5F6F8',
    accentPale: 'rgba(151,38,77,0.05)',
    live: '#0C1931',
    black: '#191919'
  },
  text: {
    base: '#0C1931',
    lighter: '#607399',
    black: '#191919',
    f: '#FFFFFF', // on: bg.primary, name: whiteだと謎に読み込まれないことがあるっぽい
    fPale: 'rgba(255,255,255,0.7)',
    fPale2: 'rgba(255,255,255,0.86)',
    fDisabled: 'rgba(255,255,255,0.38)',
    darker: '#1C386E', // on: bg.secondary
    disabled: '#9E9E9E'
  },
  border: {
    divider: '#EDEFF3', // on: bg.base
    base: '#E8E8E8',
    active: '#B9C1D2',
    prominent: '#607399',
    accent: '#97264D',
    fPale: 'rgba(255,255,255,0.25)'
  },
  shadow: {
    base: 'rgba(255,255,255, 0.1)',
    lighter: 'rgba(96,115,153,0.1)',
    card: '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)',
    bottomSheet:
      '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)'
  },
  utility: {
    green: '#1C616E', // success, done
    greenLighter: '#31a9bf',
    greenBg: '#E8EFF0',
    yellow: '#D69E70', // caution
    yellowBg: '#F6EBE2',
    red: '#D46F72', // danger, warning, failure
    redBg: '#FAF0F0'
  }
}

export const ExpirationPeriodLabels = {
  oneDay: '1 Day',
  twoWeeks: '2 Weeks',
  oneMonth: '1 Month'
} as const
export type ExpirationPeriodLabels = keyof typeof ExpirationPeriodLabels

export const LensPowerLabels = {
  on: '度あり',
  off: '度なし',
  both: '度あり, 度なし'
} as const
export type LensPowerLabels = keyof typeof LensPowerLabels
