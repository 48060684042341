import { createRouter, createWebHashHistory } from 'vue-router'
import ChatbotView from '../views/chatbotView.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: ChatbotView,
      props: (route: { query: { inspect?: string } }) => ({
        isInspectMode: route.query.inspect === undefined ? false : true
      })
    }
  ]
})

// 単一ページなのでrouterGuardは無し

export default router
