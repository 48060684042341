import { defineStore } from 'pinia'
import axiosHotelLovers, { type _AxiosError } from './api'
import { createDiscreteApi } from 'naive-ui'
import type {
  ChatTransactionDetail,
  Conversation,
  ConversationDetail,
  CurrentState,
  GetHistoryQuery
} from '@/types/conversation.type'
import type { PaginationStats } from '@/types/paginationStats.type'
import { Constants } from '@/utils/enums'
import type {
  RoutingConciergeOutput,
  RoutingConciergeOutputPipeline
} from '@/types/conversation.type'

const { message } = createDiscreteApi(['message'])
interface State {
  currentAgent: null | RoutingConciergeOutputPipeline
  currentConversation?: ConversationDetail
  currentState: CurrentState
  conversations: Conversation[]
  conversations_stats: PaginationStats | null
  // ui logic
  alreadyRatedTxIds: number[]
}
export const useConversationStore = defineStore('conversationStore', {
  state: (): State => ({
    currentAgent: null,
    currentConversation: undefined,
    currentState: [],
    conversations: [],
    conversations_stats: null,
    alreadyRatedTxIds: []
  }),
  getters: {
    get_current_transactions: (state) => {
      const arr = state.currentConversation ? [...state.currentConversation.chatTransactions] : []
      return arr.sort((a, b) => a.id - b.id)
    }
  },
  actions: {
    // chatbot
    clear_current_conversation() {
      this.$patch((state) => {
        state.currentAgent = null
        state.currentConversation = undefined
        state.currentState = []
        state.alreadyRatedTxIds = []
      })
    },
    async clear_conversation_id() {
      if (!localStorage) {
        // ブラウザ側で無効の場合は何もしない
        return
      }
      localStorage.removeItem(`conversationId-${Constants.ENV_NAME}`)
      let v = localStorage.getItem(`conversationId-${Constants.ENV_NAME}`)
      while (v) {
        console.log('try')
        await new Promise((resolve) => setTimeout(resolve, 500))
        v = localStorage.getItem(`conversationId-${Constants.ENV_NAME}`)
      }
      console.log('cleared')
    },
    get_conversation_id() {
      if (!localStorage) {
        // ブラウザ側で無効の場合は何もしない
        return -1
      }
      const id = localStorage.getItem(`conversationId-${Constants.ENV_NAME}`) || ''
      try {
        const r = parseInt(id)
        if (isNaN(r) || !isFinite(r)) {
          localStorage.removeItem(`conversationId-${Constants.ENV_NAME}`)
          return -1
        }
        return r
      } catch (e) {
        localStorage.removeItem(`conversationId-${Constants.ENV_NAME}`)
        return -1
      }
    },
    get_history(json: GetHistoryQuery) {
      axiosHotelLovers
        .get('/conversations/history', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.conversations = res.data.items
            state.conversations_stats = res.data.meta
          })
        })
        .catch((err: _AxiosError) => {
          message.error('データ取得に失敗しました')
          console.log(err)
        })
    },
    get_one_history(conversationId: number) {
      axiosHotelLovers
        .get(`/conversations/history/${conversationId}`)
        .then((res: { data: ConversationDetail }) => {
          this.currentConversation = res.data
          this.currentState = res.data.currentState
          if (localStorage) {
            localStorage.setItem(`conversationId-${Constants.ENV_NAME}`, res.data.id.toString())
          }
          this.alreadyRatedTxIds = res.data.chatTransactions
            .filter((tx) => tx.userRating !== -1)
            .map((tx) => tx.id)
        })
        .catch((err: _AxiosError) => {
          this.currentConversation = undefined
          if (localStorage) {
            localStorage.removeItem(`conversationId-${Constants.ENV_NAME}`)
          }
          console.log(err)
        })
    },
    update_user_rating(conversationId: number, chatTransactionId: number, userRating: number) {
      axiosHotelLovers
        .put(`/conversations/history/${conversationId}/userRating`, {
          chatTransactionId,
          userRating
        })
        .then(() => {
          this.$patch((state) => {
            if (state.currentConversation) {
              const tx = state.currentConversation.chatTransactions.find(
                (e) => e.id == chatTransactionId
              )
              if (tx) {
                tx.userRating = userRating
              }
            }
            if (userRating !== -1) {
              state.alreadyRatedTxIds.push(chatTransactionId)
            }
          })
          message.success('評価ありがとうございます！')
        })
        .catch((err: _AxiosError) => {
          console.log(err)
        })
    },
    routingConcierge(text: string, conversationId: number) {
      return new Promise<RoutingConciergeOutput>((resolve, reject) => {
        axiosHotelLovers
          .post('/shopping-assistant/routing-concierge', {
            message: text,
            conversationId
          })
          .then((res: { data: RoutingConciergeOutput }) => {
            this.$patch((state) => {
              if (state.currentConversation?.id == res.data.tx.conversationId) {
                state.currentConversation.chatTransactions.push(res.data.tx)
              }
              state.currentAgent = res.data.route
            })
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            message.error('エラーが発生しました')
            console.log(err)
            reject(err)
          })
      })
    },
    start(title: string) {
      return new Promise<number>((resolve, reject) => {
        axiosHotelLovers
          .post('/conversations', { title: title.slice(0, 80) })
          .then((res: { data: ConversationDetail }) => {
            if (localStorage) {
              localStorage.setItem(`conversationId-${Constants.ENV_NAME}`, res.data.id.toString())
            }
            this.currentConversation = res.data
            resolve(res.data.id)
          })
          .catch((err: _AxiosError) => {
            message.error('エラーが発生しました')
            console.log(err)
            reject(err)
          })
      })
    },
    postMinorPipeline(
      conversationId: number,
      chatTransactionId: number,
      route: RoutingConciergeOutputPipeline
    ) {
      return new Promise<void>((resolve, reject) => {
        axiosHotelLovers
          .post('/shopping-assistant/minor-pipeline', {
            conversationId,
            chatTransactionId,
            route
          })
          .then((res: { data: { tx: ChatTransactionDetail; currentState: CurrentState } }) => {
            this.$patch((state) => {
              if (state.currentConversation?.id == res.data.tx.conversationId) {
                const index = state.currentConversation.chatTransactions.findIndex(
                  (e) => e.id == res.data.tx.id
                )
                if (index !== -1) {
                  state.currentConversation.chatTransactions[index] = res.data.tx
                } else {
                  state.currentConversation.chatTransactions.push(res.data.tx)
                }
              }
              state.currentState = res.data.currentState
            })
            resolve()
          })
          .catch((err: _AxiosError) => {
            reject(err)
          })
      })
    },
    chatShoppingAssistant(conversationId: number, chatTransactionId: number) {
      return new Promise<ChatTransactionDetail>((resolve, reject) => {
        axiosHotelLovers
          .post('/shopping-assistant/chat', {
            conversationId,
            chatTransactionId
          })
          .then((res: { data: { tx: ChatTransactionDetail; currentState: CurrentState } }) => {
            this.$patch((state) => {
              if (state.currentConversation?.id == res.data.tx.conversationId) {
                const index = state.currentConversation.chatTransactions.findIndex(
                  (e) => e.id == res.data.tx.id
                )
                if (index !== -1) {
                  state.currentConversation.chatTransactions[index] = res.data.tx
                } else {
                  state.currentConversation.chatTransactions.push(res.data.tx)
                }
              }
              state.currentState = res.data.currentState
            })
            resolve(res.data.tx)
          })
          .catch((err: _AxiosError) => {
            reject(err)
          })
      })
    }
  }
})
